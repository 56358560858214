
export function Privacy() {
    return (
    <div class='privacy-terms'>
      <h1>FriendFinder Networks Privacy Policy</h1>
      <h2>I'm With You is owned by FriendFinder Networks </h2>
      <p class="updated-date">Effective: May 24, 2018</p>
  
      <div class="section">
          <h2>1. How We Collect, Use and Disclose Information</h2>
          <h3>What Information Do We Collect?</h3>
          <p>During the registration process for, or while using, any of the FFN Websites, you may choose to provide us with certain "Personal Information" including:</p>
          <ul>
              <li>Your name and email address</li>
              <li>Billing information</li>
              <li>"Sensitive Personal Information", which includes your racial or ethnic origins, political or religious beliefs, or sexuality</li>
              <li>Content that you submit, including images, audio recordings, and text that may be of sexual nature or relate to sexual orientation or preferences</li>
              <li>Information about how you use the services, including content viewed and your interactions with other users</li>
          </ul>
      </div>
  
      <div class="section">
          <h2>2. Our Use of Cookies and Your Options</h2>
          <p>FFN Websites use "cookies" to store and sometimes track information to make your online experience easier and more personalized.</p>
          <div class="important">
              <p>You may modify your computer's preferences to issue you an alert when a cookie is downloaded, or to disable the ability of third parties to download a cookie to you.</p>
          </div>
      </div>
  
      <div class="section">
          <h2>3. Collection, Use and Disclosure of Personal Information by Third Parties</h2>
          <p>This Policy only addresses the use and disclosure of Personal Information that we collect from you. FFN does not control the privacy policies of third parties, and you are subject to the privacy policies or practices of those third parties.</p>
      </div>
  
      <div class="section">
          <h2>4. Your Use of FFN Websites' Members' Information</h2>
          <div class="important">
              <p>You may not use another FFN Websites' Members' information, whether private or not, for commercial purposes, to spam, to harass, or to make unlawful threats.</p>
          </div>
      </div>
  
      <div class="section">
          <h2>5. A Special Note About Children</h2>
          <div class="important">
              <p>Children under eighteen years of age are prohibited from using the FFN Websites or services. We ask that children not submit information to us.</p>
          </div>
      </div>
  
      <div class="section">
          <h2>Contact Information</h2>
          <div class="contact">
              <p>For privacy-related inquiries, please contact us:</p>
              <ul>
                  <li>Email: privacy@friendfindernetworks.com</li>
                  <li>Mail: FriendFinder Networks Inc.<br/>
                      1615 S. Congress Avenue, Suite 103<br/>
                      Delray Beach, Florida 33445<br/>
                      Attn: Privacy</li>
                  <li>Phone: (669)208-0363</li>
              </ul>
          </div>
      </div>
  </div>
  )
}