export { default as Sensors } from './sensors';
export { ReactComponent as AccountBox } from './account-box.svg';
export { ReactComponent as AmazonA } from './amazon-a.svg';
export { ReactComponent as CallToAction } from './call-to-action.svg';
export { ReactComponent as Celebration } from './celebration.svg';
export { ReactComponent as ChatClosed } from './chat-closed.svg';
export { ReactComponent as ChatOpen } from './chat-open.svg';
export { ReactComponent as Check } from './check.svg';
export { ReactComponent as CheckCircle } from './check-circle.svg';
export { ReactComponent as Checkmark } from './checkmark-round.svg';
export { ReactComponent as ChevronDown } from './chevron-down.svg';
export { ReactComponent as ChevronLeft } from './chevron-left.svg';
export { ReactComponent as ChevronRight } from './chevron-right.svg';
export { ReactComponent as ChevronUp } from './chevron-up.svg';
export { ReactComponent as Close } from './close.svg';
export { ReactComponent as CloseFullscreen } from './close-fullscreen.svg';
export { ReactComponent as ConfettiClosed } from './confetti-closed.svg';
export { ReactComponent as ConfettiOpen } from './confetti-open.svg';
export { ReactComponent as Copy } from './copy.svg';
export { ReactComponent as CreateVideo } from './create-video.svg';
export { ReactComponent as CreateStage } from './create-stage.svg';
export { ReactComponent as Delete } from './delete.svg';
export { ReactComponent as DownArrow } from './down-arrow.svg';
export { ReactComponent as ErrorIcon } from './error.svg';
export { ReactComponent as ExpandScreen } from './expand-screen.svg';
export { ReactComponent as FactCheck } from './fact-check.svg';
export { ReactComponent as FailRobot } from './fail-robot.svg';
export { ReactComponent as Favorite } from './favorite.svg';
export { ReactComponent as Filter } from './filter.svg';
export { ReactComponent as FullScreen } from './fullscreen.svg';
export { ReactComponent as FullScreenExit } from './fullscreen-exit.svg';
export { ReactComponent as Group } from './group.svg';
export { ReactComponent as Home } from './home.svg';
export { ReactComponent as Hourglass } from './hourglass.svg';
export { ReactComponent as LeaveSession } from './leave-session.svg';
export { ReactComponent as Link } from './link.svg';
export { ReactComponent as Lock } from './lock.svg';
export { ReactComponent as Logout } from './logout.svg';
export { ReactComponent as Menu } from './menu.svg';
export { ReactComponent as MicOn } from './mic-on.svg';
export { ReactComponent as MicOff } from './mic-off.svg';
export { ReactComponent as Mute } from './mute.svg';
export { ReactComponent as NoData } from './no-data.svg';
export { ReactComponent as NoSignal } from './no-signal.svg';
export { ReactComponent as Pause } from './pause.svg';
export { ReactComponent as PersonAdd } from './person-add.svg';
export { ReactComponent as PersonOff } from './person-off.svg';
export { ReactComponent as Play } from './play.svg';
export { ReactComponent as Poll } from './poll.svg';
export { ReactComponent as RequestInvite } from './request-invite.svg';
export { ReactComponent as Search } from './search.svg';
export { ReactComponent as ScreenShare } from './screenshare.svg';
export { ReactComponent as ScreenShareOff } from './screenshare-off.svg';
export { ReactComponent as Settings } from './settings.svg';
export { ReactComponent as ShoppingBag } from './shopping-bag.svg';
export { ReactComponent as SmartToy } from './mr-404.svg';
export { ReactComponent as Stop } from './stop.svg';
export { ReactComponent as StreamHealth } from './stream-health.svg';
export { ReactComponent as StreamManager } from './stream-manager.svg';
export { ReactComponent as SyncError } from './sync-error.svg';
export { ReactComponent as Unfollow } from './unfollow.svg';
export { ReactComponent as Unmute } from './unmute.svg';
export { ReactComponent as Upload } from './upload.svg';
export { ReactComponent as VideoCamera } from './video-camera.svg';
export { ReactComponent as VideoCameraOff } from './video-camera-off.svg';
export { ReactComponent as Visibility } from './visibility.svg';
export { ReactComponent as VisibilityOff } from './visibility-off.svg';
export { ReactComponent as VolumeHigh } from './volume-high.svg';
export { ReactComponent as VolumeLow } from './volume-low.svg';
export { ReactComponent as VolumeMedium } from './volume-medium.svg';
export { ReactComponent as VolumeOff } from './volume-off.svg';
